/**
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/borderRadius.js
 */
export default {
    xxs: '0.125rem',
    xs: '0.375rem',
    sm: '0.625rem',
    md: '1rem',
    lg: '50px ',
    xl: '60px',
    xxl: '125px',
    full: '9999px'
};
