/**
 * Colors
 * ====================
 * Individual themes may have more or less colours than included here, but these are the base colours
 * which the toolkit may use.
 *
 * It is recommended to keep these colours and override them with a different colour in your app, even if you don't use
 * the colours within your own theme, to prevent unthemed parts of the toolkit.
 *
 * These colours are all prefixed with `--color`.
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/color.js
 */
import Color from 'color';

const primaryColor = Color('#FE6B2E'),
    secondaryColor = Color('#65ABDC');

export default {
    darkest: 'var(--color-navy)',
    lightest: '#fff',
    primary: primaryColor.hex(),
    'primary-light': primaryColor.lighten(0.15).hex(),
    'primary-dark': primaryColor.darken(0.25).hex(),
    secondary: secondaryColor.hex(),
    'secondary-light': '#EBF6FD',
    'secondary-dark': '#152443',

    'eau-blue': 'var(--color-secondary)',
    'light-blue': 'var(--color-secondary-light)',
    navy: 'var(--color-secondary-dark)',

    gray: {
        100: '#FDFDFE',
        200: '#F3F3F6',
        300: '#D6D6D6',
        400: '#bababa',
        500: '#707070',
        600: '#5a5a5a',
        700: '#454545',
        800: '#313131',
        900: '#101010'
    },

    'light-gray': 'var(--color-gray-200)',
    'stone-gray': 'var(--color-gray-500)',

    black: {
        10: 'rgba(0, 0, 0, 0.05)',
        20: 'rgba(0, 0, 0, 0.16)',
        30: 'rgba(0, 0, 0, 0.24)'
    },

    // These are essentially state colours used for notifications and the like.
    highlight: {
        success: '#3BDBB5',
        info: 'var(--color-light-blue)',
        warning: 'var(--color-primary)',
        error: '#E35C5C'
    }

};
