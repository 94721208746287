import spacing from './layout/spacing.js';
import grid from './layout/grid.js';
import color from './styles/color.js';
import transition from './styles/transition.js';
import borderRadius from './styles/borderRadius.js';
import boxShadow from './styles/boxShadow.js';
import font from './typography/font.js';
import lineHeight from './typography/lineHeight.js';

/**
 * This is the default theme used in your application.
 * It will be applied to some toolkit components so they will closely match your project styles without having to style them by hand.
 * For an up-to-date list of all theme variables check out
 */
export default {
    color,
    transition,
    spacing,
    grid,
    borderRadius,
    boxShadow,
    font,
    lineHeight
};
