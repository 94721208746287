/**
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/typography/font.js
 */
export default {
    family: {
        primary: [
            '"Roboto"',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif'
        ],
        secondary: [
            '"Spartan"',
            '"Helvetica Neue"',
            'Arial',
            '"Noto Sans"',
            'sans-serif'
        ]
    },

    size: {
        xxs: '0.75rem',
        xs: '0.9375rem',
        sm: '1rem',
        md: '1.125rem',
        lg: '1.25rem',
        xl: '1.375rem',
        '2xl': '1.5rem',
        '3xl': '1.625rem',
        '4xl': '1.875rem',
        '5xl': '2rem',
        '6xl': '2.125rem',
        '7xl': '2.3125rem',
        '8xl': '2.375rem',
        '9xl': '3rem'
    },

    // We have only 2 weights per font at the moment, so we apply them to all the toolkit variables
    weight: {
        primary: {
            hairline: '400',
            thin: '400',
            light: '400',
            regular: '400',
            medium: '400',
            semibold: '700',
            bold: '700',
            extrabold: '700',
            black: '700'
        },
        secondary: {
            hairline: '600',
            thin: '600',
            light: '600',
            regular: '600',
            medium: '600',
            semibold: '600',
            bold: '700',
            extrabold: '700',
            black: '700'
        }
    }

};
