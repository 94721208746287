/**
 * Transitions
 * ================
 * Use these transitions to modify your projects' animations.
 *
 * Even though you may diverge for specific components, these transitions may be used by
 * toolkit components and thus should not be removed.
 *
 * In order to improve performance, these transitions do NOT include the properties to transition,
 * as "all" should be avoided whenever possible.
 *
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/styles/transition.js
 */

export default {
    page: 'opacity var(--transition-timing-fast) var(--transition-timingFunction-out)',
    link: 'color var(--transition-fast)'
};
