/**
 * Spacings
 * ================
 * These spacings should be applied between block- and inline-block level elements in your application.
 *
 * You should avoid using many per-component custom spacings to give your apps a consistent look.
 *
 * It it reccomended to expand on these spacings with re-usable variants in your theme vars instead.
 * Per-component spacings should then only be applied as a matter of last resort.
 *
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/layout/spacing.js
 */
export default {
    '2xs': '.3125rem',
    xs: '.625rem',
    sm: '.75rem',
    md: '1rem',
    lg: '1.5rem',
    xl: '1.875rem',
    '2xl': '2rem',
    '3xl': '3.75rem',
    '4xl': '4.375rem',
    section: '3rem',
    'section-lg': '5rem'
};
