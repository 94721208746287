/**
 * Grid
 * ================
 * This grid should be used to set-up the layouts of your application
 *
 *  https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/layout/grid.js
 */
export default {
    // The side-gap specified the spacing between the browser window and the application layout
    'side-gap': {
        sm: 'var(--grid-gap-sm)',
        md: 'var(--grid-gap-md)',
        lg: 'var(--grid-gap-lg)'
    },

    // The grid gap specifies the spacing between areas in your grid layout
    gap: {
        sm: '1.5rem',
        md: '3.125rem',
        lg: '5rem'
    },

    // The maxWidth specifies the maximum widths of the layout components
    maxWidth: {
        page: '80rem',
        content: '52.75rem'
    }

};
