/**
 * Line heights
 * ==================
 * Line heights should be described in a context-relative size.
 * Eg. no unit or in 'em'.
 *
 * https://gitlab.digitalnatives.nl/toolkit/css-variables/-/blob/master/themes/default/typography/lineHeight.js
 */

export default {
    xxs: '1',
    xs: '1.2',
    sm: '1.3189',
    md: '1.4',
    lg: '1.5',
    xl: '1.875'
};
